import { Menu, Dropdown, Drawer, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { settingLang } from 'src/locales/i18n';
import { useState } from 'react';
import { homePaths } from '@app/router/path';
import './style.scss';
type header = {
    changelang: any;
    trans: any;
};
const HeaderMobile = ({ changelang, trans }: header) => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    return (
        <div id="header-right-mobile">
            <div className="header-top">
                <div className="header-top-nav"></div>
                <div className="header-top-circle">
                    <button>
                        <img src="/assets/png/search.png" alt="search button" />
                    </button>
                    <button onClick={() => changelang(settingLang[0].value)}>
                        <img src="/assets/png/vnflag.png" alt="vi" />
                    </button>
                    <button onClick={() => changelang(settingLang[1].value)}>
                        <img src="/assets/png/enflag.png" alt="en" />
                    </button>
                </div>
            </div>
            <div className="header-bot">
                <Button className='btn-menu-mb' onClick={showDrawer}>
                    <MenuOutlined className="menu-icon" />
                </Button>
                <Drawer
                    className='drawermobile'
                    placement='left'
                    width={250}
                    onClose={onClose}
                    open={open}
                >
                    <div className="mb-link-header no-border-top">
                        <Link to={homePaths.baseurl}>{trans('header.bot.home')}</Link>
                    </div>
                    <div className="mb-link-header">
                        <Link to={homePaths.baseurl + homePaths.bookingurl}>{trans('header.bot.booking')}</Link>
                    </div>
                    <div className="mb-link-header">
                        <Link to={homePaths.baseurl + homePaths.guide}>{trans('header.bot.tour')}</Link>
                    </div>
                    <div className="mb-link-header">
                        <Link to={homePaths.baseurl + homePaths.service}>{trans('header.bot.service')}</Link>
                    </div>
                    <div className="mb-link-header">
                        <Link to={homePaths.baseurl + homePaths.price}>{trans('header.bot.pricing')}</Link>
                    </div>
                    <div className="mb-link-header">
                        <Link to={homePaths.baseurl + homePaths.sale}>{trans('header.bot.saleoff')}</Link>
                    </div>
                    <div className="mb-link-header">
                        <Link to={homePaths.baseurl + homePaths.new}>{trans('header.bot.news')}</Link>
                    </div>
                    <div className="mb-link-header">
                        <Link to={homePaths.checkTicket}>{trans('header.bot.bill')}</Link>
                    </div>
                </Drawer>
            </div>
        </div>
    );
};

export default HeaderMobile;
